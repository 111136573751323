import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import './char-guide.scss';
import { NikkeCharacter } from '../../../modules/nikke/common/components/nikke-character';
import { Alert, Col, Row } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { NikkeSkill } from '../../../modules/nikke/common/components/nikke-skill';

const NikkeGuidesRem: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page char-guide'} game="nikke">
      <ul className="breadcrumb">
        <li>
          <Link to="/nikke/">NIKKE</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/nikke/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Rem guide & review</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/nikke/categories/category_rem.webp"
            alt="Wishlist"
          />
        </div>
        <div className="page-details">
          <h1>Rem guide & review</h1>
          <h2>A guide & review for Rem in NIKKE: Goddess of Victory.</h2>
          <p>
            Last updated: <strong>31/03/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Foreword" />
        <div className="employee-container for-nikke">
          <NikkeCharacter mode="icon" slug="rem" enablePopover />
        </div>
        <p>
          Our adorable blue-haired twin sister has finally joined the fray,
          commanding a heavy-duty machine gun enchanted with youthful energy and
          the omnipotence of magic. Rem is an almighty support character that
          not only provides continuous healing but also batters enemies with her
          comically large ATK buff. Her Burst Skill momentarily strengthens
          active RL units and increases their Max Ammo.
        </p>
        <SectionHeader title="Summary" />
        <h5>Can you give us a brief description?</h5>
        <p>
          Rem is a versatile unit with high DPS potential (top 8) that can also
          heal the team at the same time. She can also buff RL units. Her
          healing may not be the best, but it's still better than nothing. Rem
          is also a versatile character and can fit in many teams. Sadly, she is
          rather investment-dependent, as her DPS and lifesteal scale
          exponentially with appropriate OL.
        </p>
        <h5>Should I pull this unit?</h5>
        <p>
          Absolutely. While her performance in PvP is far from desired, it is
          our job to proselytize Rem's supremacy in Raids. Sorry, we mean to say
          that she is expected to stay relevant for a long time. In other words,
          you will without doubt gain value by using her, as long as she is
          invested properly. We would also recommend exchanging Gold Mileage
          Ticket(s) for her if you have some spare. In fact, she is worth
          obtaining additional copies since strengthening her further beyond OL
          is pretty difficult, but stat increase is straightforward. After all,
          she is a limited unit and will most likely never return in the future.
          That aside, remember that we are one month away from Half Anniversary.
        </p>
        <SectionHeader title="Kit Analysis & Highlights" />
        <h5>A Literal Otherworldly Grater</h5>
        <blockquote>
          <p>
            ■ Activates after landing 15 normal attack(s) in Demon's Breath
            status. Affects self.
          </p>
          <p>ATK ▲ 4.22%, stacks up to 30 times(s) and lasts for 10 sec.</p>
          <p>■ Activates when using Burst Skill. Affect all allies.</p>
          <p>Shares HP recovery for 10 sec.</p>
        </blockquote>
        <p>
          Rem is another outstanding unit that challenges the meta by
          undertaking two jobs at the same time: DPS and healing. Despite her
          naturally lower supporter stats, her monstrous ~126% ATK buff
          affecting self, in unison with the high base multiplier of MG, allows
          for some crazy damage. If normalized to Attacker's standards, the
          final ATK would be comparable to{' '}
          <strong>Attacker's ATK + ~88% ATK</strong>. This would be on par with
          Guillotine, or perhaps slightly higher, without the drawback of losing
          HP and reduced team flexibility. Furthermore, it ramps up much more
          quickly, reaching its cap within just one use of Burst Skill and
          persisting throughout the battle{' '}
          <strong>
            as long as subsequent Burst Skill(s) are initiated within ~9.5s of
            the previous one
          </strong>
          , which demands CDR and constant activation.
        </p>
        <Alert variant="primary">
          <p>
            Rem's DPS stems from her ridiculous ATK buff, which is not
            disappointing per se. However, it also means it will be diluted,
            making it uneconomical to scale her further with ATK. Focus on
            stacking Max Ammo, Elemental DMG, and/or buffs from other
            categories.
          </p>
        </Alert>
        <p>
          Here are some screenshots of her performance in Union Shooting Range
          (by Tae from OMNI, #1 SR player from Global):
        </p>
        <p>
          <strong>In Union Shooting Range w/ ELE Advantage:</strong>
        </p>
        <StaticImage src="../../../images/nikke/generic/rem_2.webp" alt="Rem" />
        <p>
          <strong>In Union Shooting Range w/o ELE Advantage:</strong>
        </p>
        <StaticImage src="../../../images/nikke/generic/rem_1.webp" alt="Rem" />
        <h5>Endearing Yet Avaricious</h5>
        <p>
          Not so fast, brother. Rem's extremely high personal ATK comes with a
          drawback, or a twist, we would say. That is, she will almost always
          steal all buffs that target units with the highest ATK. Why? Because
          this gauges final ATK. There are only several units who consistently
          have higher ATKs than her at the right time, such as Scarlet and SBS
          at the beginning of Full Burst. So, yes, that means she will steal one
          of Alice's and Maxwell's S1 enhancements, so be careful!
        </p>
        <p>
          Additionally, Rem's Skill 1 also provides an additional "link" to her
          Heal Share ability when her own Burst Skill is used (explained in
          detail in the next 2 sections). This adds a Heal Share link to each
          unit present in the team, as opposed to the usual 1, 2 or 3 units
          benefitting from Rem's heal depending on how many RLs are in the team.
          There is an interesting interaction that takes place here: while Rem
          is healing everyone herself, any external heal sources (such as from
          Helm, Rapunzel, etc) will also heal all units linked to this Heal
          Share Chain. Here is an example from youtuber: PESU MY, where if you
          burst with Rem and Helm both together, and had "saved" Snow White's
          Burst Skill from her previous burst, you can shoot with Snow White to
          receive a gigantic heal that fully heals your team from the brink of
          death:
        </p>
        <StaticImage src="../../../images/nikke/generic/rem_3.webp" alt="Rem" />
        <p>Team's health before and after Snow White's shot.</p>
        <p>You can check out PESU MY's youtube channel here:</p>
        <Row xs={1} xl={2} className="video-row">
          <Col>
            <YoutubeEmbed embedId="pWggCI4NfH4" />
          </Col>
        </Row>
        <h5>Sucking Fountain</h5>
        <blockquote>
          <p>■ Activates when entering battle. Affects self.</p>
          <p>Recovers 42.24% of attack damage as HP continuously.</p>
          <p>
            ■ Activates when entering battle. Affects self and 2 Rocket
            Launcher-wielding ally unit(s) with the highest ATK.
          </p>
          <p>Shares HP recovery continuously.</p>
        </blockquote>
        <p>
          Besides DPS, Rem is also capable of healing. Her damage acts as a
          lifesteal. Normally, this would only be shared among self and all RL
          units. For 10s after using Burst Skill, the scope is expanded to the
          whole team. This not only makes Rem the fourth B2 healer (after Blanc,
          Naga, and Marciana) but also the most versatile one concerning
          synergy. Laplace's last ammo mechanic may be affected but not to the
          point that she loses damage. Rem is also not tethered to any other
          unit to perform, although her offensive buffs do only work for a
          particular weapon type.
        </p>
        <Alert variant="primary">
          <p>
            Her HP Recovery Share actually works unidirectionally! What this
            means is that any healing provided by any of the associated units is
            distributed evenly among that sharing group. For instance, given
            Volume + Rem + Diesel — if Rem's Burst Skill is used and Full Burst
            is triggered, then any healing Diesel receives from taking damage
            will be split among the team.
          </p>
        </Alert>
        <h5>Any% Insanity Speedrun</h5>
        <p>
          The sharing system is also quite convoluted. The easiest way to
          visualize this is to picture a reservoir projecting troughs that
          connect to your units, healing them. When an instance of HP Recovery
          Share is active, every unit involved gets one share, splitting the
          healing pool any of the linked units provides. However, the number of
          troughs hooked to each unit varies,{' '}
          <strong>so the healing is not even</strong>! The more channels, the
          more healing (scales multiplicatively) for them. However, since the
          total number of shares increases, other units who are entitled to
          fewer portions will also receive less healing at the same time. This
          is because the amount of healing per share is equal to
          <strong>Healing Pool / Total Number of Shares.</strong>
        </p>
        <StaticImage src="../../../images/nikke/generic/rem_4.webp" alt="Rem" />
        <p>HP Recovery Share Diagram</p>
        <p>
          Rem's kit consists of two HP Recovery Share instances. One is linked
          to her and a maximum of 2 RL units with the highest ATK, whereas the
          other one is linked to ALL units in team (when using Burst Skill).
          Therefore, we can infer that:
        </p>
        <ul>
          <li>
            When the effect of Burst Skill is not active, Rem and a maximum of 2
            RL units each receive one share from the pool. If there are no RLs,
            Rem will devour all of it. When there is only one, Rem and that RL
            will each consume half.
          </li>
          <li>
            When the effect of Burst Skill is active, another instance of HP
            Recovery Share activates. This time, all units are entitled to one
            <i>additional</i> share from the pool. (NOTE TO ANTILLAR, PLEASE DO
            NOT REMOVE THE ITALIC HERE)
          </li>
          <ul>
            <li>
              Yes, I'm a pepega for not reading notes, so will leave this here
              as a punishment - <strong>Antillar</strong>.
            </li>
          </ul>
          <li>
            But, this does not mean that there are 5 shares in total.
            Referencing the first bullet point, Rem and the relevant RL units
            will actually have 2 shares each. Hence, the total number of shares
            will also shift depending on whether there are RLs in the team, from
            6 (no RL) to 8 (2 RLs).
          </li>
          <li>
            More shares result in less healing per share. With 0 RL, each share
            amounts to 1/6 with Burst Skill. With 1, it equals 1/7. Lastly, with
            2, it equals 1/8.
          </li>
        </ul>
        <Alert variant="primary">
          <p>
            A unit at maximum HP with at least one share still dilutes the pool,
            so Rem's healing works better for AoE than Single Target.
          </p>
        </Alert>
        <h5>Clash of the Two Waifus</h5>
        <p>
          Her playstyle resembles Marciana's inasmuch as how her healing
          functions. The difference lies on the fact that one instance of
          Marciana's healing skills is derived from each ally's own damage,
          whereas Rem's is <strong>dependent on her personal damage</strong>. On
          top of this, Rem's healing is weak, but thankfully it can be
          compensated by investments. This means you need to focus on building
          Rem's DPS too, and that is primarily achieved via OL. One thing to
          also note is that, due to Elemental Advantage, Rem would also be
          healing more against enemies of the countered type (such as against
          Nihilister).
        </p>
        <p>
          In summary, Rem can be seen as a more offense-inclined unit compared
          to Marciana since Marciana's heals are more consistent. On the
          contrary, Rem deals a hefty amount of damage. Marciana also does not
          need to use Burst Skill to heal, making it possible to run her as an
          Off-Burst B2 unit. Meanwhile, Rem is always required to use Burst
          Skill. Otherwise, Rem will <strong>NOT</strong> obtain any ATK buffs,
          lowering her healing potential and significantly weakening her. Even
          then, nothing can beat Rem's dualistic contribution.
        </p>
        <h5>Selective Love</h5>
        <blockquote>
          <p></p> ■ Affects self.
          <p>Demon's Breath: Critical Rate ▲ 37.8% for 10 sec.</p>
          <p>■ Affects all allies with a Rocket Launcher.</p>
          <p>ATK ▲ 50.78% of caster's ATK for 10 sec.</p>
          <p>Max Ammunition Capacity ▲ 5 round(s) for 10 sec.</p>
        </blockquote>
        <p>
          Finally, let's discuss the effect of her Burst Skill itself. To begin,
          she obtains increased CRIT Rate for 10 seconds, boosting her overall
          damage and healing. This is always a welcome buff, even if its value
          is not that significant. But, that is nowhere as impactful as the
          second component, where she dramatically increases the ATK of all RL
          units in team, analogous to Tove's, for 10s. The buff is equal to ~50%
          of caster's ATK (~41.67% in Attackers' stats at similar investments),
          alongside a Max Ammo increase of +5 (at max level). This strongly
          synergizes with Emilia's Max Ammo mechanic and SBS's infinite ammo
          strat (especially when paired with Alice as she loses ammo faster).
          Emilia is the main protagonist here as her kit has no ATK buff at all,
          thus she utilizes Rem's buff efficiently. Meanwhile, SBS is already
          overcharged with her ~115% ATK buff, but extra buffs would not hurt,
          would they?
        </p>
        <Alert variant="primary">
          <p>
            We would like to remind you that S1 does not increase the strength
            of this ATK buff.
          </p>
        </Alert>
        <p>
          While it is optional to pair her with RLs, to benefit from her
          offensive utilities, RL units must be present somewhat. This can be of
          Attacker, Supporter, or Defender type as they will all benefit
          equally. Note that, in practice, you do not always need to bring 3 RL
          DPS. Sometimes, you can use better units that deal even more damage
          without any offensive support.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Usage Analysis" />
        <p>
          The ranks below have been adapted to our most recent tier list update.
          We'd encourage you to check if you haven't seen.
        </p>
        <h5>
          Campaign (LD) - <strong className="ss">SS</strong>
        </h5>
        <p>
          The addition of Rem is fantastic news for campaigners. She exhibits
          near-Modernia Level of Single-Target DPS while also being able to take
          care of hitcount mobs/projectiles, which are a major threat beyond
          Chapter 19+. In addition, she also provides supportive heals to RL
          units like A2 or Emilia, challenging Centi in terms of versatility.
          While Centi shows consistency at any deficit as an energy battery, we
          believe that Rem is a suitable alternative for low-deficit clears,
          allowing for a more offensive approach, provided that the team can
          accumulate Burst Energy through other ways.
        </p>
        <h5>
          Campaign (HD) - <strong className="s">S</strong>
        </h5>
        <p>
          While Rem is usable at high deficit, Bunnies (and Tiga) are still
          better for pushing campaign stages due to the nature, comfort, and
          flexibility of their heals, damage buffs, indomitability and ammo
          buffs. Rem's heals are also too low to become meaningful in
          high-deficit combats, where nearly everything is a one-shot, one-kill.
        </p>
        <h5>
          Boss (Solo) - <strong className="ss">SS</strong>
        </h5>
        <p>
          A dynamic B2 contender that blends offense and survivability, making
          her a versatile unit for all sorts of situations. Unbelievable
          personal damage (top 8 in our calculation) and can fit in many teams.
          If her heal is insufficient, then you can bring another healer like
          Helm. Buff restrictions on RL may hinder supportive performance but
          should not weaken her impact. In fact, it may unlock the potential of
          even ancient RL characters. Being the third water "DPS" after XLud and
          Emilia, she seems to be promising and should hold for a very long time
          in Solo Raid, especially against Fire bosses. Since Team 1 and 2 are
          already occupied by Bunnies and Tiga, we can expect to use Rem in Team
          3.
        </p>
        <Alert variant="primary">
          <p>
            Specifically in Solo Raid, Rem will most likely have the highest ATK
            at all times, causing her to taunt attacks naturally. This can be
            good or bad. Pay close attention!
          </p>
        </Alert>
        <h5>
          Boss (Adds) - <strong className="ss">SS</strong>
        </h5>
        <p>
          For this, we will do a direct comparison against the Bunny twins.
          While Rem can closely compete with Blanc in terms of the full team's
          raw damage, she offers less utility that the team can benefit off,
          subtracting their DPS damage in exchange for her own damage. With no
          in-built AoE skill, this will become more apparent in Boss (adds). A
          few things she has a lead over Bunnies is that you are not forced to
          bring Noir with you (which can be advantageous for some formations).
          For instance, swapping Noir with Emilia can help deal with ranged
          mobs. Besides that, MGs can also handle hitcount mobs/projectiles and
          shields with ease, notable in Plate and Mother Whale Boss Campaign
          boss fight, and Material H Solo Raid fight.
        </p>
        <h5>
          PVP - <strong className="c">C</strong>
        </h5>
        <p>
          MGs are not viable for PvP and can cause more harm than good, unless
          it's Rosanna. However, Rem has a HP Recovery Share effect + decent
          damage that doesn't trigger additional procs. She also buffs the ATK
          of RL units, so it's a nice bonus if you ever get to burst with her.
          Her potential may be unlocked if she is able to make use of her HP
          Recovery Share effect nicely. These strategies will only be viable for
          ATK strats, and are not good for DEF strats. For example, an
          overhealing DPS (with Helm) can now split their excessive healing
          among the team.
        </p>
        <h5>Simulation Overclock</h5>
        <p>
          One of the only B2 MG and Water "DPS" that is worth considering when
          the restrictions favor her in the future.
        </p>
        <SectionHeader title="Investments" />
        <h5>Gear Investments</h5>
        <p>
          Rem is amazingly good at dealing damage, and only by dealing damage is
          she healing the team. To put it simply, her DPS delimits her
          potential. There are two main ways of increasing it:
        </p>
        <ul>
          <li>
            <strong>Max Ammo</strong>. MGs' damage skyrockets in proportion to
            uptime, which can only be ideally achieved using Max Ammo via OL.
          </li>
          <li>
            <strong>Elemental DMG</strong>. Against enemies of countered type,
            especially Nihilister (the next Solo Raid boss), Rem's DPS and
            healing will easily multiply.
          </li>
        </ul>
        <p>
          Other than these two, both ATK and CRIT lines can serve as bonuses
          that will help boost her damage slightly. Even if ATK is already
          heavily diluted, there is really nothing else better.{' '}
        </p>
        <h5>Skill Investments</h5>
        <p>
          Unfortunately, it is a common understanding that Rem swallows hundreds
          of skill books, but you can get away with fewer:
        </p>
        <ul>
          <li>
            <strong>Skill 1: 10</strong>
          </li>
          <ul>
            <li>
              ATK is her main source of damage (and healing). Prioritize
              leveling to 10.
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <strong>Skill 2: 5+</strong>
          </li>
          <ul>
            <li>
              Rem's healing is weak, so in some cases, it may be insufficient.
              If this happens, we recommend settling somewhere above 5 and
              upwards if necessary. The reason we choose 5 is because the cost
              of Blue Manual(s) to upgrade from 4 to 5 is only 42, but then it
              leaps to 126 at level 6. Blue Manual is often the bottleneck for
              regular skills. However, before upgrading, we advise trying a more
              polished assortment of OLs to see if that itself is enough to fix
              the problem. Only upgrade in hindsight.
            </li>
            <li className="highlight">
              <strong>
                Instead of trying to fix something that is expensive, why not
                consider other alternative team formations?
              </strong>
            </li>
          </ul>
          <li>
            <strong>Burst: 3/4/7/10</strong>
          </li>
          <ul>
            <li>
              At Level 3 & 7, her Burst Skill raises the Max Ammo cap further by
              +1. However, we do not recommend leaving it at 3, or you'll be
              missing out on the ATK buff. Therefore, our general recommendation
              will be 4/7/10.
            </li>
            <li className="highlight">
              <strong>
                Since Emilia is the only RL unit worth investing right now aside
                from SBS, it may not be worthwhile to upgrade beyond 7. If in
                the future they release a new RL unit that is performing well,
                you can then revisit this idea.
              </strong>
            </li>
          </ul>
        </ul>
        <p>
          <strong>Suggested Progression Path</strong>
        </p>
        <div className="nikke-skills-tips">
          <div className="nikke-skills-row header">
            <div className="column character">&nbsp;</div>
            <div className="column suggestions pve">PVE Investment</div>
            <div className="column suggestions pvp">PVP Investment</div>
            <div className="column priority">Priority</div>
          </div>
          <div className="nikke-skills-row data">
            <NikkeSkill char="rem" />
          </div>
        </div>
        <h5>Cube Investments</h5>
        <StaticImage
          src="../../../images/nikke/generic/cube_4_sm.png"
          alt="Guide"
        />
        <ul>
          <li>
            <strong>Bastion</strong>. The universal choice for all MGs as it
            offers the highest uptime extension without external help.
          </li>
          <li>
            <strong>Resilience</strong>. If paired with reload speed buffers
            like Privaty, Resilience (with some Max Ammo) may reduce downtime to
            the point that it is unfelt.
          </li>
        </ul>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="PVE Team Compositions" />
        <h5>Team #1: The Remilia Squad</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rem" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="emilia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          Emilia's reliance on Max Ammo and absence of ATK buff allows her to
          scale purely from Rem's buffs. Dolla/SHelm can be slotted in as
          Off-Burst B2s, allowing the usage of non-CDR B1. Noise + Sakura are
          some unique options too. The FLEX-es can be Power, A2, Laplace, or
          even SBS. Non-RL units are also fine. Deploying RLs (in particular A2)
          may make Rem a great supporter for low-deficit campaign pushes.
        </p>
        <h5>Team #2: Re:SBS Squad</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rem" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="scarlet-black-shadow"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="alice" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-flex">Flex</h5>
            <div className="placeholder-box">Flex</div>
          </div>
        </div>
        <p>
          There is a hidden synergy between SBS and Rem. While the Max Ammo
          increase is obvious, and that beginners will most definitely benefit
          from it (because you want to attain "no reload"), nothing beats the
          taunt swapping idea. Why is that? SBS (with Burst Skill's ATK buff)
          will be able to divert attacks off Rem momentarily in Solo Raid,
          allowing her to heal before resuming to tank again. This will prevent
          unwanted deaths caused by Rem being targeted and bombarded constantly.
        </p>
        <Alert variant="primary">
          <p>
            Rem will ALWAYS eat one portion of Alice's S1 buff, but this doesn't
            mean SBS or Alice will be sabotaged. In SBS's turn to Burst, she
            will consume the other half. In Alice's, she will consume it
            instead. It is not optimal, but well... sometimes the non-optimal
            wins at the end.
          </p>
        </Alert>
        <h5>Team #3: Farewell, Marciana! Squad</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rem" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="scarlet" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Sometimes, more healing is not really necessary. Rem has shown to
          provide a much higher damage output, which is what we care most of the
          time. In this team, Rem also enjoys the "short reload" strat, which
          helps increase her damage.
        </p>
        <h5>Team #4: The WaterWorks Squad</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="dorothy" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rem" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="ludmilla-winter-owner"
                enablePopover
              />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter
                mode="icon"
                slug="sparkling-summer-anis"
                enablePopover
              />
              <NikkeCharacter mode="icon" slug="emilia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="privaty" enablePopover />
            </div>
          </div>
        </div>
        <p>
          Full water team! xLud buffs the team while Rem buffs Emilia. Emilia
          can be changed to SAnis if you're planning to use this team for Union
          Raid since she is a better damage dealer and parts are usually weak in
          Union Raids unless you have a low sync level.
        </p>
        <h5>Team #5: Healing Support!</h5>
        <div className="nikke-team-showcase">
          <div className="team-slot">
            <h5 className="burst-1">Burst 1</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="liter" enablePopover />
              <NikkeCharacter mode="icon" slug="volume" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-2">Burst 2</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="rem" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="employee-container for-nikke">
              <NikkeCharacter mode="icon" slug="helm" enablePopover />
              <NikkeCharacter mode="icon" slug="emilia" enablePopover />
            </div>
          </div>
          <div className="team-slot">
            <h5 className="burst-3">Burst 3</h5>
            <div className="placeholder-box">B3</div>
          </div>
        </div>
        <p>
          In cases where you feel that Rem's healing isnt enough, you can slot
          in Helm for bonus heals. This still allows you to run 2 B3 units, and
          overall 3 DPS units in your team (Rem included). The final slot of B3
          can be filled by Nikkes like Emilia, Scarlet or even Modernia, if need
          be.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297247"></div>
        <SectionHeader title="Final ratings" />
        <div className="ratings-container">
          <div className="ratings-box s">
            <h6>S</h6>
            <p>Story (High Deficit)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Story (Low Deficit)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Boss (Solo)</p>
          </div>
          <div className="ratings-box ss">
            <h6>SS</h6>
            <p>Boss (Adds)</p>
          </div>
          <div className="ratings-box c">
            <h6>C</h6>
            <p>PVP</p>
          </div>
        </div>
        <SectionHeader title="Pros & Cons" />
        <Row className="pros-cons">
          <Col sm="12" md="6">
            <div className="box pros">
              <h5>Pros</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>2nd Best Water DPS after xLudmilla.</li>
                  <li>
                    Very high single-target DPS, especially for bossing, and is
                    unrivaled in the B2 category.
                  </li>
                  <li>Provides ATK buffs and Ammo buffs to RL units.</li>
                  <li>
                    High synergy with RL units since she heals RLs well as
                    compared to other units.
                  </li>
                  <li>
                    Permanent ATK buff from Skill 1, has good DPS outside of
                    Burst as well.
                  </li>
                  <li>
                    Can tank damage against raptures with "target Highest ATK"
                    skills since she heals herself better than she heals others.
                  </li>
                  <li>
                    Provides substantial Crit Rate during Burst, which is
                    appreciated against Bosses with no core.
                  </li>
                  <li>
                    Heals from other linked units are shared with all of the
                    team.
                  </li>
                  <li>Best girl.</li>
                </ul>
              </div>
            </div>
          </Col>
          <Col sm="12" md="6">
            <div className="box cons">
              <h5>Cons</h5>
              <hr />
              <div className="raw list">
                <ul>
                  <li>Limited Unit.</li>
                  <li>
                    Needs a lot of skill investments to be fully optimal,
                    especially Max Ammo OL, as well as lvl 10 Skill 1.
                  </li>
                  <li>Does not scale well with ATK buffs.</li>
                  <li>
                    Will steal Maxwell's Skill 1 and Alice's Skill 1 buffs for
                    herself due to innate ATK buff.
                  </li>
                  <li>
                    Can lose her Skill 1 ATK buff stacks if playing on lower
                    FPS, low Burst Gen, no CDR or low Investments.
                  </li>
                  <li>
                    Healing can be extremely diluted for non RL units. Rem can
                    be an unreliable healer at times.
                  </li>
                  <li>
                    At best, Rem will heal herself and 1 or 2 RLs paired with
                    her, the other 2 units may struggle to survive.
                  </li>
                  <li>
                    Since Rem is an MG unit, Lower FPS = Lower damage. (For MGs,
                    Bullets per second = FPS).
                  </li>
                  <li>Please fix her hands Shift Up.</li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default NikkeGuidesRem;

export const Head: React.FC = () => (
  <Seo
    title="Rem guide & review | NIKKE | Prydwen Institute"
    description="A guide & review for Rem in NIKKE: Goddess of Victory."
    game="nikke"
  />
);
